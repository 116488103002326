export default {
	methods: {
		setMetaSidearm(title, tags) {
			if (title) document.title = title + ' | ' + this.$root.site_title;
			else document.title = this.$root.site_title;

			Array.from(
				document.querySelectorAll('[data-vue-router-controlled]'),
			).map(el => el.parentNode.removeChild(el));

			if (tags && tags.length) {
				tags.map(tagDef => {
					const tag = document.createElement('meta');

					Object.keys(tagDef).forEach(key => {
						tag.setAttribute(key, tagDef[key]);
					});
					tag.setAttribute('data-vue-controlled', '');

					return tag;
				}).forEach(tag => document.head.appendChild(tag));
			}
		},
	},
};
