<template>
	<nav
		class="component c-navigation--main c-navigation position-fixed top-0 left-0 width-100 z-index-1000"
		v-click-outside="close_dropdowns"
	>
		<h2 class="accessible-hide">{{ sectionTitle }}</h2>
		<div
			class="c-navigation__social bg-color-white align-items-center flex-basis-100 display-flex justify-content-center bp-768:justify-content-flex-end bp-768:padding-r-12 bp-1440:padding-r-24"
		>
			<a
				v-for="(item, index) in icons"
				:key="index"
				:href="item.url"
				class="padding-x-12"
				:aria-label="item.title"
			>
				<span :class="'sidearm-icon reset-icon ' + item.icon"></span>
			</a>
		</div>
		<div
			class="c-navigation__row display-flex justify-content-space-between"
		>
			<div
				class="c-navigation__logo display-flex align-items-center flex-item-auto bg-color-white position-relative z-index-1"
			>
				<router-link
					to="/"
					class="display-block padding-x-12 bp-1280:border-r bp-1280:padding-l-48 bp-1024:padding-r-40 text-decoration-none width-100"
				>
					<img
						class="c-navigation__logo-img display-block"
						src="@/assets/main_logo.svg"
						alt="NCAA Logo"
					/>
				</router-link>
			</div>
			<div
				class="c-navigation-desktop flex-item-1 height-100 display-none bp-1280:display-block"
			>
				<ul
					class="c-navigation__level-1 reset-list flex-item-1 height-100 utility-flex-center"
				>
					<li
						class="c-navigation__item--level-1 height-100 transition"
						:aria-haspopup="
							item.items && item.items.length ? 'true' : 'false'
						"
						:class="[
							item.items && item.items.length
								? 'c-navigation__parent'
								: undefined,
							item.is_open ? 'is-open' : undefined,
						]"
						v-for="item in data"
						:key="item.id"
					>
						<a
							class="c-navigation__url c-navigation__url--level-1 font-weight-700 font-size-18 height-100 padding-x-12 bp-1440:padding-x-24 utility-flex-center text-transform-uppercase text-decoration-none text-on-primary hover:bg-color-white transition"
							:aria-label="item.title"
							:aria-haspopup="
								item.items && item.items.length
									? 'true'
									: 'false'
							"
							:aria-expanded="item.is_open ? 'true' : 'false'"
							:title="item.title"
							:class="
								item.id === 3 || item.id === 4 || item.id === 5
									? 'c-navigation__division'
									: undefined
							"
							href="javascript:void(0)"
							@click="toggle_menu_item(item)"
							@keyup.enter="toggle_menu_item(item)"
							v-if="item.items.length && item.id < data.length"
							>{{ item.title }}</a
						>
						<navigation-link
							_class="c-navigation__url c-navigation__url--level-1 font-weight-700 font-size-18 height-100 padding-x-12 bp-1440:padding-x-24 utility-flex-center text-transform-uppercase text-decoration-none text-on-primary hover:bg-color-white transition"
							:_aria-label="item.title"
							:_target="
								item.open_in_new_window ? '_blank' : '_self'
							"
							:_title="item.title"
							:url="item.url"
							_tab-index="0"
							v-if="!item.items.length"
							>{{ item.title }}</navigation-link
						>
						<div
							class="c-navigation__level-2 position-absolute top-100 left-0 width-100"
							:class="item.is_open ? 'transition' : undefined"
							v-if="item.items.length && item.id < data.length"
						>
							<div
								class="c-navigation__level-2-inner display-flex margin-x-auto padding-t-32 padding-b-72 padding-x-12"
							>
								<ul
									class="c-navigation__column reset-list flex-item-1 margin-x-32"
									v-for="column in item.columns"
									:key="column.column"
								>
									<li
										class="c-navigation__item--level-2"
										v-for="_item in column.items"
										:key="_item.id"
										:class="[
											_item.separator
												? 'c-navigation__separator font-size-16 font-weight-700 padding-12'
												: '',
											_item.class_name,
										]"
									>
										<navigation-link
											_class="c-navigation__url c-navigation__url--level-2 display-flex align-items-center font-size-16 padding-12 text-default text-decoration-none hover:text-on-primary hover:text-decoration-underline transition"
											v-if="!_item.separator"
											:_aria-label="_item.title"
											:url="_item.url ? _item.url : ''"
											:_tab-index="
												item.is_open ? '0' : undefined
											"
											:_target="
												_item.open_in_new_window
													? '_blank'
													: '_self'
											"
											:_title="_item.title"
										>
											<img
												class="margin-r-6"
												height="30px"
												src="@/assets/main_logo.svg"
												alt="NCAA Logo"
												v-if="
													_item.class_name ===
														'include-logo'
												"
											/>
											<span>{{
												_item.title
											}}</span></navigation-link
										>
										<span v-if="_item.separator">{{
											_item.title
										}}</span>
									</li>
								</ul>
							</div>
						</div>
					</li>
				</ul>
			</div>
			<div class="c-navigation-mobile bp-1280:display-none height-100">
				<div
					class="c-navigation__offcanvas bg-color-white position-absolute top-100 right-0 width-100"
					:class="mobile_menu_open ? 'is-open' : undefined"
				>
					<ul class="c-navigation__level-1 reset-list padding-b-72">
						<li
							class="c-navigation__item--level-1 border-b"
							v-for="item in data"
							:key="item.id"
							:class="[
								item.items && item.items.length
									? 'c-navigation__parent'
									: undefined,
								item.is_open ? 'is-open' : undefined,
							]"
						>
							<a
								class="c-navigation__url c-navigation__url--level-1 align-items-center display-flex font-size-18 font-weight-700 padding-24 text-default text-decoration-none text-transform-uppercase transition width-100"
								tabindex="0"
								:aria-label="item.title"
								:aria-haspopup="
									item.items && item.items.length
										? 'true'
										: 'false'
								"
								:aria-expanded="item.is_open ? 'true' : 'false'"
								:title="item.title"
								href="javascript:void(0)"
								@click.prevent="
									item.items && item.items.length
										? toggle_menu_item(item)
										: undefined
								"
								@keyup.enter="
									item.items && item.items.length
										? toggle_menu_item(item)
										: undefined
								"
								v-if="
									item.items.length && item.id < data.length + 1
								"
							>
								{{ item.title }}
							</a>
							<navigation-link
								_class="c-navigation__url c-navigation__url--level-1 align-items-center display-flex font-size-18 font-weight-700 padding-24 text-default text-decoration-none text-transform-uppercase transition hover:bg-color-primary"
								:_aria-label="item.title"
								:_target="
									item.open_in_new_window ? '_blank' : '_self'
								"
								:_title="item.title"
								:url="item.url ? item.url : ''"
								:tab-index="'0'"
								v-if="
									!item.items.length && item.id < data.length
								"
								>{{ item.title }}</navigation-link
							>
							<div
								class="c-navigation__level-2"
								v-if="item.items.length"
								:class="item.is_open ? 'is-open' : undefined"
							>
								<ul class="reset-list bg-color-surface">
									<li
										class="c-navigation__item--level-2"
										v-for="_item in item.items"
										:key="_item.id"
										:class="[
											_item.separator
												? 'c-navigation__separator font-size-18'
												: undefined,
											_item.class_name,
										]"
									>
										<navigation-link
											_class="c-navigation__url c-navigation__url--level-2 border-b display-block font-size-16 padding-24 text-default text-decoration-none transition hover:bg-color-primary hover:text-on-primary"
											v-if="!_item.separator"
											:url="_item.url ? _item.url : ''"
											:tab-index="
												item.is_open ? '0' : undefined
											"
											>{{ _item.title }}</navigation-link
										>
										<span
											class="display-block border-b font-weight-700 padding-24"
											v-if="_item.separator"
											>{{ _item.title }}</span
										>
									</li>
								</ul>
							</div>
						</li>
					</ul>
				</div>
			</div>
			<search
				class="margin-l-auto bp-1280:margin-l-auto bp-1280:margin-r-24 bp-1440:margin-r-48"
			/>
			<div
				class="c-navigation__register--desktop font-weight-700 font-size-18 height-100 display-none bp-1280:display-block"
				v-for="item in data"
				:key="item.id"
			>
				<div
					class="c-navigation__register--desktop__item--level-1 height-100 transition"
					:aria-haspopup="
						item.items && item.items.length ? 'true' : 'false'
					"
					:class="[
						item.items && item.items.length
							? 'c-navigation__register--desktop__parent'
							: undefined,
						item.is_open ? 'is-open' : undefined,
					]"
				>
					<a
						class="c-navigation__register--desktop__url--level-1 display-flex height-100 align-items-center text-transform-uppercase padding-r-48 padding-l-32 bp-1440:padding-r-64 bp-1440:padding-l-48 text-decoration-none transition"
						:aria-label="item.title"
						:aria-haspopup="
							item.items && item.items.length
								? 'true'
								: 'false'
						"
						:aria-expanded="item.is_open ? 'true' : 'false'"
						:title="item.title"
						:class="
							item.id === 3 || item.id === 4 || item.id === 5
								? 'c-navigation__division'
								: undefined
						"
						href="javascript:void(0)"
						@click="toggle_menu_item(item)"
						@keyup.enter="toggle_menu_item(item)"
						v-if="item.items.length && item.id == data.length"
					>
						<span class="display-block">{{ item.title }}</span>
					</a>
					<div
						class="c-navigation__register--desktop__level-2 position-absolute top-100 right-0"
						:class="item.is_open ? 'transition' : undefined"
						v-if="item.items.length && item.id == data.length"
					>
						<div
							class="c-navigation__register--desktop__level-2-inner display-flex margin-x-auto padding-t-12 padding-b-24 padding-x-24"
						>
							<ul
								class="c-navigation__register--desktop__column reset-list flex-item-1"
								v-for="column in item.columns"
								:key="column.column"
							>
								<li
									class="c-navigation__register--desktop__item--level-2"
									v-for="_item in column.items"
									:key="_item.id"
									:class="[
										_item.separator
											? 'c-navigation__register--desktop__separator font-size-16 font-weight-700 padding-12'
											: '',
										_item.class_name,
									]"
								>
									<navigation-link
										_class="c-navigation__register--desktop__url c-navigation__register--desktop__url--level-2 display-flex align-items-center font-size-16 padding-12 text-default text-decoration-none hover:text-on-primary hover:text-decoration-underline transition"
										v-if="!_item.separator"
										:_aria-label="_item.title"
										:url="_item.url ? _item.url : ''"
										:_tab-index="
											item.is_open ? '0' : undefined
										"
										:_target="
											_item.open_in_new_window
												? '_blank'
												: '_self'
										"
										:_title="_item.title"
									>
										<img
											class="margin-r-6"
											height="30px"
											src="@/assets/main_logo.svg"
											alt="NCAA Logo"
											v-if="
												_item.class_name ===
													'include-logo'
											"
										/>
										<span
											class="margin-x-auto"
										>{{
											_item.title
										}}</span></navigation-link
									>
									<span v-if="_item.separator">{{
										_item.title
									}}</span>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
			<button
				class="c-navigation__toggle bp-1280:display-none reset height-100 border-l"
				:class="mobile_menu_open ? 'is-toggled' : undefined"
				@click.prevent="toggle_mobile_menu"
			>
				<svg
					class="hamburger height-100 width-100"
					viewBox="0 0 100 100"
				>
					<path
						class="line top"
						d="m 30,33 h 40 c 3.722839,0 7.5,3.126468 7.5,8.578427 0,5.451959 -2.727029,8.421573 -7.5,8.421573 h -20"
					/>
					<path class="line middle" d="m 30,50 h 40" />
					<path
						class="line bottom"
						d="m 70,67 h -40 c 0,0 -7.5,-0.802118 -7.5,-8.365747 0,-7.563629 7.5,-8.634253 7.5,-8.634253 h 20"
					/>
				</svg>
			</button>
		</div>
	</nav>
</template>

<script>
import { get } from '@/helpers';
import baseComponent from '@/mixins/baseComponent';
import NavigationLink from '@/components/Common/NavigationLink';
import Search from '@/components/Search';

export default {
	name: 'MainNavigation',

	mixins: [baseComponent],

	components: {
		NavigationLink,
		Search,
	},

	data: () => ({
		mobile_menu_open: false,
		icons: [
			{
				title: 'Twitter',
				icon: 'sf-twitter',
				url: 'https://twitter.com/NCAA',
			},
			{
				title: 'Facebook',
				icon: 'sf-facebook-2',
				url: 'https://www.facebook.com/ncaa1906',
			},
			{
				title: 'Instagram',
				icon: 'sf-instagram',
				url: 'https://www.instagram.com/ncaa/',
			},
			{
				title: 'YouTube',
				icon: 'sf-youtube-play',
				url: 'https://www.youtube.com/user/ncaa',
			},
		],
	}),

	methods: {
		open_mobile_menu() {
			this.mobile_menu_open = true;
		},

		close_mobile_menu() {
			this.mobile_menu_open = false;
		},

		toggle_mobile_menu() {
			this.mobile_menu_open = !this.mobile_menu_open;
		},

		open_menu_item(item) {
			item.is_open = true;
		},

		close_menu_item(item) {
			item.is_open = false;
		},

		toggle_menu_item(item) {
			if (this.data) {
				this.data.forEach(_item => {
					if (_item.id != item.id && _item.is_open) {
						_item.is_open = false;
					}
				});

				item.is_open = !item.is_open;
			}
		},

		close_dropdowns() {
			if (this.data) {
				this.data.forEach(item => {
					if (item.is_open) {
						this.close_menu_item(item);
					}
				});
			}
		},
	},

	created() {
		let request_options = {
			type: 'main-navigation',
		};

		get(
			`${this.$root.proxy}/services/adaptive_components.ashx`,
			request_options,
		)
			.then(response => {
				response.forEach(data => {
					data.is_open = false;
				});
				this.original_data = response;
			})
			.catch(error => {
				console.log(error);
			});
	},

	watch: {
		$route(to, from) {
			if (to.path != from.path) {
				this.close_mobile_menu();
				this.close_dropdowns();
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.c-navigation--main {
	height: calc(var(--header-height) + 40px);
	transition: top 0.15s;
	html.not-top & {
		top: -40px;
	}
	&:after {
		content: '';
		width: 100%;
		height: 2px;
		left: 0;
		top: 100%;
		position: absolute;
		background-color: var(--color-dark-blue);
	}
	background-color: var(--color-default-theme-page);

	.c-navigation {
		&__row {
			height: var(--header-height);
		}
		&__social {
			height: 40px;
			border-bottom: 2px solid var(--color-dark-blue);
			a {
				font-size: 24px;
				text-decoration: none;
				color: var(--color-dark-blue);
				transition: color 0.25s;
				&:hover,
				&:focus {
					color: var(--color-primary);
				}
			}
		}
		//for naming
		&__logo-img {
			height: 48px;
			width: 48px;
		}
		&__my-apps {
			flex: 0 0 60px;
			width: 60px;

			&:hover,
			&:focus {
				background: var(--color-text-on-primary);
				color: var(--color-primary);
			}
		}
		&__toggle {
			flex: 0 0 70px;
			outline: none;
			width: 70px;

			&:hover,
			&:focus {
				cursor: pointer;
			}

			.hamburger {
				transition: transform 400ms;
				.line {
					fill: none;
					transition: stroke-dasharray 400ms, stroke-dashoffset 400ms;
					stroke: var(--color-dark-blue);
					stroke-width: 3;
					&.top {
						stroke-dasharray: 40 160;
					}
					&.middle {
						stroke-dasharray: 40 142;
						transform-origin: 50%;
						transition: transform 400ms;
					}
					&.bottom {
						stroke-dasharray: 40 85;
						transform-origin: 50%;
						transition: transform 400ms, stroke-dashoffset 400ms;
					}
				}
			}
			&:hover,
			&:focus,
			&.is-toggled {
				background: var(--color-dark-blue);
				.hamburger {
					.line {
						stroke: var(--color-text-on-primary);
					}
				}
			}
			&.is-toggled {
				.hamburger {
					transform: rotate(45deg);
					.line {
						&.top {
							stroke-dashoffset: -64px;
						}
						&.middle {
							transform: rotate(90deg);
						}
						&.bottom {
							stroke-dashoffset: -64px;
						}
					}
				}
			}
		}
		&__url {
			&:hover,
			&:focus {
				cursor: pointer;
			}
		}
		&__register {
			&--desktop {
				&__item--level-1 {
					&.is-open {
						.c-navigation__register--desktop__url--level-1 {
							background-color: var(--color-primary);
							color: var(--color-text-on-primary);

							&::after {
								border-top-color: var(--color-text-on-primary);
								transform: rotate(-180deg) skew(30deg);
							}
						}

						.c-navigation__register--desktop__level-2 {
							opacity: 1;
							pointer-events: auto;
						}
					}
				}
				&__url--level-1 {
					font-family: supria-sans-condensed, sans-serif;
					font-weight: 700;
					margin-right: -24px;
					transform: skew(-30deg);
					background-color: var(--color-default-theme-page);
					color: var(--color-dark-blue);
					span {
						transform: skew(30deg);
					}
					&:focus {
						background-color: var(--color-default-theme-page);
						color: var(--color-dark-blue);
					}
					&:hover{
						background-color: var(--color-primary);
						color: var(--color-text-on-primary);
					}
				}
				&__url--level-2 {
					font-family: supria-sans-condensed, sans-serif;
					font-weight: 700;
					&:hover,
					&:focus {
						background-color: var(--color-primary);
						color: var(--color-text-on-primary);
					}
				}
				&__parent {
					.c-navigation__register--desktop__url--level-1::after {
						content: '';
						width: 0;
						height: 0;
						border-left: 5px solid transparent;
						border-right: 5px solid transparent;
						border-top: 6px solid var(--color-dark-blue);
						margin-left: 6px;
						transition: 0.25s;
						transform: skew(30deg);
					}

					&:hover {
						.c-navigation__register--desktop__url--level-1::after {
							border-top-color: var(--color-text-on-primary);
						}
					}
				}
				&__level-2 {
					opacity: 0;
					pointer-events: none;

					&-inner {
						box-shadow: 0px 2px 19px rgba(0, 0, 0, 0.08);
						border-bottom: 6px solid var(--color-primary);
						max-width: var(--grid-width);
						background-color: var(--color-nav-lvl2-gray);
						width: 199px;
					}
				}
				&__separator {
					color: var(--color-primary);
					&:first-child {
						padding-top: 0 !important;
					}
				}
				&__url--level-2 {
					font-size: 16px;
				}
				&__item--level-2 {
					border-bottom: 1px solid var(--color-nav-border-gray);
				}
			}
		}
	}

	.c-navigation-mobile {
		.c-navigation {
			//for naming
			&__offcanvas {
				height: 0;
				overflow: hidden;

				&.is-open {
					height: calc(100vh - var(--header-height) - 40px);
					overflow: auto;
					html.not-top & {
						height: calc(100vh - var(--header-height));
					}
				}
			}

			&__parent {
				.c-navigation__url--level-1 {
					justify-content: space-between;
					font-family: supria-sans-condensed, sans-serif;
					font-weight: 700;
					&::after {
						content: '';
						width: 0;
						height: 0;
						border-left: 5px solid transparent;
						border-right: 5px solid transparent;
						border-top: 6px solid var(--color-dark-blue);
						transition: 0.25s;
					}
				}

				&.is-open {
					.c-navigation__url--level-1 {
						background: var(--color-dark-blue);
						color: var(--color-text-on-primary);

						&::after {
							border-top-color: var(--color-text-on-primary);
							transform: rotate(-180deg);
						}
					}
				}

				&:hover,
				&:focus {
					.c-navigation__url--level-1 {
						background: var(--color-dark-blue);
						color: var(--color-text-on-primary);

						&::after {
							border-top-color: var(--color-text-on-primary);
						}
					}
				}
			}

			&__level-2 {
				height: 0;
				overflow: hidden;

				&.is-open {
					height: auto;
					overflow: auto;
				}
			}

			&__item--level-2 {
				font-family: supria-sans-condensed, sans-serif;
				font-weight: 700;
				&:last-of-type {
					border-bottom: none;
				}
			}

			&__separator {
				color: var(--color-dark-blue);
			}

			&__division-home .c-navigation__url--level-2 {
				color: var(--color-dark-blue);
				font-weight: 700;

				&:hover,
				&:focus {
					color: var(--color-text-on-primary);
				}
			}

			&__url--level-2 {
				&:hover,
				&:focus {
					background: var(--color-dark-blue);
					color: var(--color-text-on-primary);
				}
			}
		}
	}

	.c-navigation-desktop {
		.c-navigation {
			//for naming
			&__item--level-1 {
				&.is-open {
					.c-navigation__url--level-1 {
						background: var(--color-default-theme-surface);
						color: var(--color-dark-blue);

						&::after {
							border-top-color: var(--color-dark-blue);
							transform: rotate(-180deg);
						}
					}

					.c-navigation__level-2 {
						opacity: 1;
						pointer-events: auto;
					}
				}
			}
			&__url--level-1 {
				font-family: supria-sans-condensed, sans-serif;
				font-weight: 700;
				&:hover {
					color: var(--color-dark-blue);
				}
			}
			&__url--level-2 {
				font-family: supria-sans-condensed, sans-serif;
				font-weight: 700;
				&:hover,
				&:focus {
					background-color: var(--color-primary);
					color: var(--color-text-on-primary);
				}
			}
			&__parent {
				.c-navigation__url--level-1::after {
					content: '';
					width: 0;
					height: 0;
					border-left: 5px solid transparent;
					border-right: 5px solid transparent;
					border-top: 6px solid var(--color-default-theme-surface);
					margin-left: 6px;
					transition: 0.25s;
				}

				&:hover {
					.c-navigation__url--level-1::after {
						border-top-color: var(--color-dark-blue);
					}
				}
			}
			&__level-2 {
				opacity: 0;
				pointer-events: none;

				&-inner {
					box-shadow: 0px 2px 19px rgba(0, 0, 0, 0.08);
					border-bottom: 6px solid var(--color-primary);
					max-width: var(--grid-width);
					background-color: var(--color-nav-lvl2-gray);
				}
			}
			&__separator {
				color: var(--color-primary);
				&:first-child {
					padding-top: 0 !important;
				}
			}
			&__division-home .c-navigation__url--level-2 {
				color: var(--color-primary);
				font-weight: 700;

				&:hover,
				&:focus {
					color: var(--color-text-on-primary);
				}
			}
			&__url--level-2 {
				font-size: 16px;
			}
			&__item--level-2 {
				border-bottom: 1px solid var(--color-nav-border-gray);
			}
		}
	}
}

@media screen and (min-width: 1280px) {
	.c-navigation--main {
		background-color: var(--color-dark-blue);
		.c-navigation {
			//for naming
			&__my-apps {
				flex: 0 0 auto;
				width: auto;

				&-icon {
					border: 1px solid var(--color-default-theme-surface);
					border-radius: 100%;
					height: 46px;
					transition: 0.25s;
					width: 46px;
				}

				&:hover,
				&:focus {
					background: none;
					color: var(--color-text-on-primary);

					.c-navigation__my-apps-icon {
						background: var(--color-text-on-primary);
						color: var(--color-primary);
					}
				}
			}

			&__logo {
				//height: calc(100% + 3px);
				margin-left: -24px;
				transform: skew(-30deg);
				&-img {
					height: 69px;
					transform: skew(30deg);
					width: 69px;
				}
			}
		}
	}
}

@media screen and (min-width: 1440px) {
	.c-navigation--main {
		.c-navigation {
			//for naming
			&__register--desktop__level-2-inner {
				width: 231px;
			}
		}
	}
}
</style>

<style lang="scss">
.c-navigation--main {
	.c-navigation-desktop {
		.c-navigation {
			//for naming
			&__url--level-1 {
				&:hover {
					color: var(--color-primary);
				}
			}
		}
	}
}
</style>
