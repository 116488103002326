import Vue from 'vue';
import App from './App.vue';
import router from './router';
import VueLuxon from 'vue-luxon';
import VuePackeryPlugin from 'vue-packery-plugin';
import VueLazyload from 'vue-lazyload';
import VueYoutube from 'vue-youtube';
import setMetaSidearm from '@/mixins/setMetaSidearm';
import DomPortal from 'vue-dom-portal';
import { get, sort_by } from '@/helpers';

// Needed to make Swiper 6 work :upside_down_face:
import {
	Swiper as SwiperClass,
	Pagination,
	Navigation,
	Keyboard,
	Autoplay,
	Lazy,
} from 'swiper/swiper.esm';
import getAwesomeSwiper from 'vue-awesome-swiper/dist/exporter';
SwiperClass.use([Pagination, Navigation, Keyboard, Autoplay, Lazy]);
Vue.use(getAwesomeSwiper(SwiperClass));
const { Swiper, SwiperSlide } = getAwesomeSwiper(SwiperClass);
import 'swiper/swiper-bundle.min.css';
////

// register jw pagination component globally
import JwPagination from 'jw-vue-pagination';
Vue.component('jw-pagination', JwPagination);

Vue.use(DomPortal);
Vue.use(VueLuxon);
Vue.use(VuePackeryPlugin);
Vue.use(VueLazyload, {
	lazyComponent: true,
	observer: true,
});
Vue.use(VueYoutube);

Vue.config.productionTip = false;

Vue.directive('clickOutside', {
	bind: function(el, binding, vnode) {
		el.clickOutsideEvent = function(event) {
			if (!(el == event.target || el.contains(event.target))) {
				vnode.context[binding.expression](event);
			}
		};
		document.body.addEventListener('click', el.clickOutsideEvent);
	},
	unbind: function(el) {
		document.body.removeEventListener('click', el.clickOutsideEvent);
	},
});

Vue.mixin(setMetaSidearm);

new Vue({
	router,
	render: h => h(App),
	data: () => ({
		bucket: '//s3.amazonaws.com/ncaa.org',
		divisions: [],
		proxy: 'https://ncaaorg-old.sidearmsports.com',
		site_title: 'NCAA.org - The Official Site of the NCAA',
		tags: [],
	}),
	methods: {
		get_divisions() {
			get(`${this.$root.proxy}/services/ncaa.ashx/divisions`, {})
				.then(response => {
					this.divisions = response.sort(sort_by('name'));
				})
				.catch(error => {
					console.log(error);
				});
		},

		get_tags() {
			get(`${this.$root.proxy}/services/ncaa.ashx/tags`, {})
				.then(response => {
					let unique_tags = new Set();
					this.tags = response
						.reduce((acc, tag) => {
							if (!unique_tags.has(tag.id)) {
								unique_tags.add(tag.id, tag);
								acc.push(tag);
							}
							return acc;
						}, [])
						.sort(sort_by('name'));
				})
				.catch(error => {
					console.log(error);
				});
		},
	},
	created() {
		this.get_divisions();
		this.get_tags();
	},
}).$mount('#app');
