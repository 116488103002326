export const clean_response_data = (data, options) => {
	if (options.type && options.type === 'stories') {
		data.forEach(story => {
			story.url = story.url.replace('.aspx', '');
		});
		return data;
	}
	return data;
};

export const create_url_params_from_object = params_obj => {
	let param_string = '';

	Object.keys(params_obj).forEach((key, index) => {
		param_string += index === 0 ? '?' : '&';

		param_string += `${key}=${encodeURIComponent(params_obj[key])}`;
	});

	return param_string;
};

export const get = (
	url = '',
	options = {},
	callback = data => {
		return data;
	},
) => {
	return new Promise((resolve, reject) => {
		const param_string = create_url_params_from_object(options);

		fetch(url + param_string)
			.then(response => response.json())
			.then(response => {
				if (response.error) {
					reject(response.error);
					return;
				}

				if (response.data) {
					resolve(
						callback(clean_response_data(response.data, options)),
					);
					return;
				}

				resolve(callback(clean_response_data(response, options)));
			})
			.catch(response => {
				if (response.error) {
					reject(response.error);
					return;
				}

				reject(response);
			});
	});
};

export const sort_by = property_name => {
	return (a, b) =>
		a[property_name] < b[property_name]
			? -1
			: a[property_name] > b[property_name]
			? 1
			: 0;
};

export const get_unique_values_of_property = (array, property) => {
	let _array = [];
	array.forEach(item => {
		_array.push(item[property]);
	});
	return [...new Set(_array)];
};
