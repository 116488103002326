/* eslint-disable */
import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
	{
		path: '/',
		name: 'Home',
		component: () =>
			import(/* webpackChunkName: "Home" */ '../views/Home.vue'),
	},
	{
		path: '/search',
		name: 'Search',
		component: () =>
			import(/* webpackChunkName: "Search" */ '../views/Search.vue'),
		props: route => ({ query: route.query.q }),
	},
	{
		path: '/form/:id',
		name: 'Form',
		component: () =>
			import(/* webpackChunkName: "Form" */ '../views/Form.vue'),
		props: true,
	},
	{
		path: '/galleries',
		name: 'Galleries',
		component: () =>
			import(/* webpackChunkName: "Galleries" */ '../views/Galleries.vue'),
		props: true,
	},
	{
		path: '/staff',
		name: 'Staff',
		component: () =>
			import(/* webpackChunkName: "Staff" */ '../views/Staff.vue'),
		props: true,
	},
	{
		path: '/media',
		name: 'Media Center',
		component: () =>
			import(
				/* webpackChunkName: "MediaCenter" */ '../views/MediaCenter.vue'
			),
	},
	{
		path: '/about/resources/media-center',
		name: 'Media Center',
		component: () =>
			import(
				/* webpackChunkName: "MediaCenter" */ '../views/MediaCenter.vue'
			),
	},
	{
		path: '/about/resources/media-center/news',
		name: 'Stories Archives',
		component: () =>
			import(
				/* webpackChunkName: "StoryArchives" */ '../views/stories/StoryArchives.vue'
			),
	},
	{
		path: '/documents/:year/:month/:day/:filepath',
		name: 'Documents',
		props: true,
		component: () =>
			import(/* webpackChunkName: "Document" */ '../views/Document.vue'),
		props: true,
	},
	{
		path: '/d1',
		name: 'Division I',
		component: () =>
			import(/* webpackChunkName: "Division" */ '../views/Division.vue'),
		props: true,
		meta: {
			division_id: 3,
			slideshow_hero: 7,
			slideshow_feature: 8,
			slideshow_cards: 9,
			slideshow_panels: 10,
			slideshow_linklist: 11,
			subnav: true,
		},
	},
	{
		path: '/d2',
		name: 'Division II',
		component: () =>
			import(/* webpackChunkName: "Division" */ '../views/Division.vue'),
		props: true,
		meta: {
			division_id: 2,
			slideshow_hero: 12,
			slideshow_feature: 13,
			slideshow_cards: 14,
			slideshow_panels: 15,
			slideshow_linklist: 16,
			subnav: true,
		},
	},
	{
		path: '/d3',
		name: 'Division III',
		component: () =>
			import(/* webpackChunkName: "Division" */ '../views/Division.vue'),
		props: true,
		meta: {
			division_id: 1,
			slideshow_hero: 22,
			slideshow_feature: 23,
			slideshow_cards: 24,
			slideshow_panels: 25,
			slideshow_linklist: 26,
			subnav: true,
		},
	},
	{
		path: '/sport-science-institute',
		name: 'Operator',
		component: () =>
			import(/* webpackChunkName: "Operator" */ '../views/Operator.vue'),
		//name: 'Sport Science Institute',
		//component: () =>
			//import(/* webpackChunkName: "Category" */ '../views/Category.vue'),
		//props: true,
		//meta: {
			//sport_id: 6,
			//subnav: true,
		//}, Converted to normal page at client request
	},
	{
		path: '/research',
		name: 'Operator',
		component: () =>
			import(/* webpackChunkName: "Operator" */ '../views/Operator.vue'),
		//name: 'Research',
		//component: () =>
			//import(/* webpackChunkName: "Category" */ '../views/Category.vue'),
		//props: true,
		//meta: {
			//sport_id: 9,
			//subnav: true,
		//}, Converted to normal page at client request
	},
	{
		path: '/inclusion',
		name: 'Operator',
		component: () =>
			import(/* webpackChunkName: "Operator" */ '../views/Operator.vue'),
		//name: 'Inclusion',
		//component: () =>
			//import(/* webpackChunkName: "Category" */ '../views/Category.vue'),
		//props: true,
		//meta: {
			//sport_id: 7,
			//subnav: true,
		//}, Converted to normal page at client request
	},
	{
		path: '/leadership-development',
		name: 'Operator',
		component: () =>
			import(/* webpackChunkName: "Operator" */ '../views/Operator.vue'),
		//name: 'Leadership Development',
		//component: () =>
			//import(/* webpackChunkName: "Category" */ '../views/Category.vue'),
		//props: true,
		//meta: {
			//sport_id: 8,
			//subnav: true,
		//}, Converted to normal page at client request
	},
	{
		path:"/opportunity",
		beforeEnter() { location.href = 'http://s3.amazonaws.com/static.ncaa.org/opportunity/index.html' }
	},
	{
		path:"/static/*",
		beforeEnter(to) { 
			let full_path = to.fullPath.replace(/^\/|\/$/g, '');
			location.href = `http://s3.amazonaws.com/static.ncaa.org/${full_path}/index.html`
		 }
	},
	{
		path: '/404',
		name: 'Missing',
		component: () =>
			import(/* webpackChunkName: "Missing" */ '../views/Missing.vue'),
	},
	{
		path: '*',
		name: 'Operator',
		component: () =>
			import(/* webpackChunkName: "Operator" */ '../views/Operator.vue'),
	},
    //redirects
	{
		path: '/contactec',
		redirect: '/form/13',
	},
	{
		path: '/contactinternational',
		redirect: '/form/22',
	},
    {
		path: '/student-athletes/future/ncaa-eligibility-center-international-contact-form',
        redirect: '/form/22',
    },
	{
		path: '/pgip',
		redirect: '/about/resources/leadership-development/postgraduate-internship-program'
	},
	{
		path: '/ssi',
		redirect: '/sport-science-institute',
	},
	{
		path: '/unitedasone',
		redirect: '/about/resources/media-center/news/student-athletes-release-unity-pledge-logo',
	},
	{
		path: '/enforcement/d1',
		redirect: '/division-i-enforcement',
	},
	{
		path: '/d1-academic-certification',
		beforeEnter(to, from, next) {
		  window.location.href = "https://s3.amazonaws.com/fs.ncaa.org/Docs/DI+Cert/index.html";
		}
	},
	{
		path: '/studentfaq',
		beforeEnter(to, from, next) {
		  window.location.href = "https://ncaa.egain.cloud/kb/EligibilityHelp/home";
		}
	},
	{
		path: '/programhub',
		beforeEnter(to, from, next) {
		  window.location.href = "https://web1.ncaa.org/CRS/exec/home";
		}
	},
	{
		path: '/hsportal',
		beforeEnter(to, from, next) {
		  window.location.href = "https://web3.ncaa.org/hsportal/exec/links?linksSubmit=ShowActiveLinks";
		}
	}

];

const router = new VueRouter({
	mode: 'history',
	base: '/',
	/* Previous logic: base: process.env.NODE_ENV === 'production' ? '/demo/' : '/', */
	routes,
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition;
		} else {
			return { x: 0, y: 0 };
		}
	},
});

router.afterEach((to, from) => {
	if (window.gtag) {
		if (to.name === 'Missing' && from && from.fullPath != '/') {
			try {
				window.gtag('event', 'page_view', {
					page_location: window.location.href,
					page_path: to.fullPath + '?url=' + from.fullPath,
				});
			} catch (e) {
				console.warn(e);
			}
		} else {
			try {
				window.gtag('event', 'page_view', {
					page_location: window.location.href,
					page_path: to.fullPath,
				});
			} catch (e) {
				console.warn(e);
			}
		}
	}

	try {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
		  'event': 'dataLayerLoaded',
		  'non_interaction_flag': true,
		  'gaPropertyID':'UA-195103076-18',
		  'schoolAttributes':{
			'Division':'SPECIALPROJECTS',
			'ssClientID':'1934'
		  }
		});
	  }
	  catch(e) {
		console.warn(e);
	  }
});

export default router;
