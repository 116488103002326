<template>
	<div
		class="component c-search align-items-center display-flex height-100 position-relative"
		v-click-outside="close_modal"
	>
		<button
			class="c-search__toggle reset text-on-primary transition border-l"
			:class="toggled ? 'is-open' : undefined"
			@click.prevent="toggle_modal"
			@keyup.enter="toggle_modal"
		>
			<search-icon v-if="!toggled"></search-icon>
			<x-icon v-else></x-icon>
		</button>
		<div
			class="c-search__popout bg-color-white display-flex position-absolute top-100 right-0 transition"
			:class="toggled ? 'is-toggled' : undefined"
		>
			<input
				label="Search input"
				placeholder="Search..."
				ref="search_input"
				type="text"
				class="c-search__input border-l border-t border-b flex-item-1 height-100 padding-x-12 reset text-brand"
				v-model="query"
				@keyup.enter="search"
			/>
			<button
				class="c-search__submit border flex-item-auto height-100 reset transition"
				@click="search"
				@keyup.enter="search"
				:disabled="query === ''"
			>
				<arrow-right-circle-icon
					class="c-search__submit-icon display-inline-block"
				></arrow-right-circle-icon>
			</button>
		</div>
	</div>
</template>

<script>
import { ArrowRightCircleIcon, SearchIcon, XIcon } from 'vue-feather-icons';

export default {
	name: 'Search',

	components: {
		ArrowRightCircleIcon,
		SearchIcon,
		XIcon,
	},

	data: () => ({
		toggled: false,
		query: '',
	}),

	methods: {
		search() {
			if (this.$route.query.q != this.query) {
				this.$router.push({
					path: '/search',
					query: { q: this.query },
				});
				this.close_modal();
				this.query = '';
			}
		},

		open_modal() {
			this.toggled = true;
			this.$refs.search_input.focus();
		},

		close_modal() {
			this.toggled = false;
		},

		toggle_modal() {
			this.toggled = !this.toggled;
			if (this.toggled) {
				this.$refs.search_input.focus();
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.c-search {
	&__toggle {
		height: 100%;
		width: 70px;
		background: var(--color-text-on-primary);
		color: var(--color-dark-blue);
		&:hover,
		&:focus,
		&.is-open {
			background: var(--color-dark-blue);
			cursor: pointer;
			color: var(--color-text-on-primary);
		}
	}

	&__popout {
		height: 46px;
		opacity: 0;
		pointer-events: none;
		transform: scale(0.8);
		transform-origin: top right;
		width: 250px;

		&.is-toggled {
			opacity: 1;
			pointer-events: auto;
			transform: scale(1);
		}
	}

	&__submit {
		background: var(--color-text-on-primary);
		color: var(--color-primary);
		flex: 0 0 46px;

		&-icon {
			transition: transform 0.25s;
			transform: translateX(0);
		}

		&:hover,
		&:focus {
			cursor: pointer;

			.c-search__submit-icon {
				transform: translateX(3px);
			}
		}

		&:disabled,
		&[disabled] {
			&:hover,
			&:focus {
				cursor: not-allowed;

				.c-search__submit-icon {
					transform: translateX(0);
				}
			}
		}
	}
}

@media screen and (min-width: 1280px) {
	.c-search {
		&__toggle {
			border: 1px solid var(--color-default-theme-surface);
			border-radius: 100%;
			height: 46px;
			width: 46px;
			background: var(--color-dark-blue);
			color: var(--color-text-on-primary);
			&:hover,
			&:focus,
			&.is-open {
				background: var(--color-text-on-primary);
				color: var(--color-dark-blue);
			}
		}
	}
}
</style>
