<template functional>
	<span>
		<a
			v-if="props && props.url && props.url.includes('http')"
			:class="props._class"
			:title="props._title"
			:aria-label="props._ariaLabel"
			:tabindex="props._tabIndex"
			:target="props._target"
			:href="props.url"
			rel="noopener"
		>
			<slot></slot>
		</a>
		<router-link
			v-else
			:class="props._class"
			:title="props._title"
			:aria-label="props._ariaLabel"
			:tabindex="props._tabIndex"
			:to="props.url"
		>
			<slot></slot>
		</router-link>
	</span>
</template>

<script>
export default {
	name: 'NavigationLink',

	props: {
		url: {
			required: true,
			type: String,
			default: '',
		},
		_title: {
			type: String,
		},
		_class: {
			type: String,
		},
		_ariaLabel: {
			type: String,
		},
		_tabIndex: {
			type: String,
		},
		_target: {
			type: String,
		},
	},
};
</script>
