import { Breakpoint } from '@/classes';

export default {
	props: {
		template: {
			type: String,
			default: '',
		},
		sectionTitle: {
			type: String,
			required: true,
		},
		count: {
			type: Number,
			default: 10,
		},
		start: {
			type: Number,
			default: 0,
		},
		dummy: {
			type: Boolean,
			default: false,
		},
		breakpoints: {
			type: Object,
			default: null,
		},
		sport_id: {
			type: Number,
			default: 0,
		},
		division_id: {
			type: Number,
			default: 0,
		},
	},
	data: () => ({
		original_data: null,
		current_breakpoint: {
			count: null,
			skip: null,
			template: null,
		},
		loading: false,
	}),
	methods: {
		setup_breakpoints() {
			let matched_breakpoint = new Breakpoint();

			if (this.breakpoints && Object.keys(this.breakpoints).length) {
				window.addEventListener('resize', event => {
					Object.keys(this.breakpoints).forEach(key => {
						const breakpoint = this.breakpoints[key];
						if (
							window.matchMedia(`(min-width: ${key}px)`).matches
						) {
							matched_breakpoint = breakpoint;
							if (matched_breakpoint.skip === undefined) {
								matched_breakpoint.skip = 0;
							}
						}
					});
					if (matched_breakpoint) {
						if (this.current_breakpoint !== matched_breakpoint) {
							this.current_breakpoint = matched_breakpoint;
						}
					} else {
						this.current_breakpoint = new Breakpoint({
							count: this.count,
							skip: 0,
							template: this.template,
						});
					}
				});
				window.dispatchEvent(new Event('resize'));
			} else {
				this.current_breakpoint = new Breakpoint({
					count: this.count,
					skip: 0,
					template: this.template,
				});
			}
		},
	},
	computed: {
		data() {
			if (this.current_breakpoint.count) {
				return this.original_data.slice(
					0,
					this.current_breakpoint.count,
				);
			}

			return this.original_data;
		},
	},
	beforeDestroy() {
		window.removeEventListener('resize', event);
	},
};
