<template>
	<nav
		class="component c-navigation c-navigation--sub text-default-on-dark position-fixed left-0 width-100 padding-x-12 z-index-200 bp-1024:padding-l-24 no-print"
		v-click-outside="close_dropdowns"
	>
		<h2 class="accessible-hide">Sub Navigation</h2>
		<div
			class="row max-width-grid flex-wrap-nowrap height-100"
			v-show="items.length"
		>
			<div
				class="c-navigation__section-title margin-r-auto flex-shrink-0"
			>
				<router-link
					to="/"
					class="c-navigation__section-title-link height-100 font-size-21 text-default-on-dark utility-flex-center font-weight-700 text-transform-uppercase text-decoration-none"
					v-if="items.length"
				>
					{{ items[0].title }}
				</router-link>
			</div>
			<button
				class="c-navigation__toggle reset utility-flex-center cursor-pointer overflow-hidden flex-shrink-0 margin-r-n12 bp-1024:display-none"
				:class="{ 'is-toggled': mobile_menu_open }"
				@click.prevent="toggle_mobile_menu"
			>
				<svg
					class="hamburger height-100 width-100"
					viewBox="0 0 100 100"
				>
					<path
						class="line top"
						d="m 30,33 h 40 c 3.722839,0 7.5,3.126468 7.5,8.578427 0,5.451959 -2.727029,8.421573 -7.5,8.421573 h -20"
					/>
					<path class="line middle" d="m 30,50 h 40" />
					<path
						class="line bottom"
						d="m 70,67 h -40 c 0,0 -7.5,-0.802118 -7.5,-8.365747 0,-7.563629 7.5,-8.634253 7.5,-8.634253 h 20"
					/>
				</svg>
			</button>
			<div
				class="c-navigation__offcanvas position-absolute left-0 width-100 bp-1024:position-relative bp-1024:left-auto bp-1024:width-auto"
				:class="{ 'is-open': mobile_menu_open }"
			>
				<ul
					class="c-navigation__level-1 reset-list bp-1024:display-flex bp-1024:height-100"
				>
					<li
						class="c-navigation__item c-navigation__item--level-1 height-100 transition border-b bp-1024:border-none"
						:aria-haspopup="
							item.items && item.items.length ? 'true' : 'false'
						"
						:class="{
							'c-navigation__parent':
								item.items && item.items.length,
							'is-open': item.is_open,
						}"
						v-for="(item, index) in items.slice(1)"
						:key="index"
					>
						<a
							class="c-navigation__url c-navigation__url--level-1 display-flex text-decoration-none align-items-center justify-content-space-between padding-24 text-transform-uppercase font-weight-700 font-size-18 bp-1024:font-size-16 bp-1024:text-transform-none bp-1024:text-default-on-dark bp-1024:padding-x-12 bp-1024:padding-y-0 bp-1024:height-100 transition hover:text-on-primary"
							:aria-label="item.title"
							:aria-haspopup="
								item.items && item.items.length
									? 'true'
									: 'false'
							"
							:aria-expanded="item.is_open ? 'true' : 'false'"
							:title="item.title"
							href="javascript:void(0)"
							@click="toggle_menu_item(item)"
							@keyup.enter="toggle_menu_item(item)"
							v-if="
								(item.items && item.items.length) || !item.url
							"
						>
							{{ item.title }}
						</a>
						<navigation-link
							_class="c-navigation__url c-navigation__url--level-1 display-flex text-on-light text-decoration-none align-items-center justify-content-space-between padding-24 text-transform-uppercase font-weight-700 font-size-18 bp-1024:font-size-16 bp-1024:text-transform-none bp-1024:text-default-on-dark bp-1024:padding-x-12 bp-1024:padding-y-0 bp-1024:height-100 transition hover:text-on-primary"
							:_aria-label="item.title"
							:_target="
								item.open_in_new_window ? '_blank' : '_self'
							"
							:_title="item.title"
							:url="item.url"
							_tab-index="0"
							v-else
						>
							{{ item.title }}
						</navigation-link>
						<div
							class="c-navigation__level-2"
							v-if="item.items && item.items.length"
							:class="{ 'is-open': item.is_open }"
						>
							<ul class="reset-list">
								<li
									class="c-navigation__item c-navigation__item--level-2 border-t bp-1024:border-dark-t"
									v-for="_item in item.items"
									:key="_item.id"
									:class="{
										'c-navigation__separator font-size-18':
											_item.separator,
									}"
								>
									<navigation-link
										_class="c-navigation__url c-navigation__url--level-2 text-on-light display-flex padding-24 text-decoration-none  bp-1024:padding-y-12 bp-1024:padding-x-16 bp-1024:text-default-on-dark transition hover:text-on-primary"
										:url="_item.url ? _item.url : ''"
										:tabindex="item.is_open ? '0' : '-1'"
									>
										{{ _item.title }}
									</navigation-link>
								</li>
							</ul>
						</div>
					</li>
				</ul>
			</div>
		</div>
	</nav>
</template>

<script>
import { get } from '@/helpers';
import NavigationLink from '@/components/Common/NavigationLink';

export default {
	name: 'SubNavigation',

	props: {
		type: {
			type: String,
			default: '',
			required: true,
		},
		division: {
			type: Number,
			default: 0,
		},
		sport_id: {
			type: Number,
			default: 0,
		},
	},

	components: {
		NavigationLink,
	},

	data: () => ({
		items: [],
		mobile_menu_open: false,
	}),

	methods: {
		get_data() {
			this.items = [];

			let request_options = {
				type: this.type,
			};

			if (this.division && this.division != 0) {
				request_options.division = this.division;
			}

			if (this.sport_id && this.sport_id != 0) {
				request_options.sport_id = this.sport_id;
			}

			get(
				`${this.$root.proxy}/services/adaptive_components.ashx`,
				request_options,
			)
				.then(response => {
					this.items = response.map(item => {
						return { ...item, is_open: false };
					});
				})
				.catch(error => {
					console.log(error);
				});
		},

		open_mobile_menu() {
			this.mobile_menu_open = true;
		},

		close_mobile_menu() {
			this.mobile_menu_open = false;
		},

		toggle_mobile_menu() {
			this.mobile_menu_open = !this.mobile_menu_open;
		},

		open_menu_item(item) {
			item.is_open = true;
		},

		close_menu_item(item) {
			item.is_open = false;
		},

		toggle_menu_item(item) {
			this.items.forEach(_item => {
				if (_item.id != item.id && _item.is_open) {
					_item.is_open = false;
				}
			});

			item.is_open = !item.is_open;
		},

		close_dropdowns() {
			this.items.forEach(item => {
				if (item.is_open) {
					this.close_menu_item(item);
				}
			});
		},
	},

	created() {
		this.get_data();
	},

	watch: {
		$route() {
			this.close_mobile_menu();
			this.close_dropdowns();
			this.get_data();
		},
	},
};
</script>

<style lang="scss" scoped>
.c-navigation {
	height: var(--sub-header-height);
	top: calc(var(--header-height) + 40px);
	transition: top 0.15s;
	font-family: supria-sans-condensed, sans-serif;
	font-weight: 700;
	&__url {
		&:hover {
			background-color: var(--color-subnav-hover-gray);
		}
	}
	html.not-top & {
		top: var(--header-height);
	}
	background-color: black;

	&__toggle {
		width: var(--sub-header-height);

		.hamburger {
			transition: transform 400ms;
			.line {
				fill: none;
				transition: stroke-dasharray 400ms, stroke-dashoffset 400ms;
				stroke: var(--color-default-theme-surface);
				stroke-width: 3;
				&.top {
					stroke-dasharray: 40 160;
				}
				&.middle {
					stroke-dasharray: 40 142;
					transform-origin: 50%;
					transition: transform 400ms;
				}
				&.bottom {
					stroke-dasharray: 40 85;
					transform-origin: 50%;
					transition: transform 400ms, stroke-dashoffset 400ms;
				}
			}
		}
		&.is-toggled {
			.hamburger {
				transform: rotate(45deg);
				.line {
					&.top {
						stroke-dashoffset: -64px;
					}
					&.middle {
						transform: rotate(90deg);
					}
					&.bottom {
						stroke-dashoffset: -64px;
					}
				}
			}
		}
	}

	&__offcanvas {
		top: var(--sub-header-height);
	}

	@media screen and (max-width: 1023px) {
		&__offcanvas {
			height: 0;
			overflow: auto;
			color: var(--color-dark-blue);
			&.is-open {
				height: calc(100vh - var(--full-header-height));
			}
		}

		&__offcanvas,
		&__level-1 {
			background: var(--color-default-theme-surface);
			color: var(--color-dark-blue);
		}

		&__level-2 {
			height: 0;
			overflow: hidden;
			a{
				color:var(--color-dark-blue);
				&:hover{
					color:white;
				}
			}
			&.is-open {
				height: auto;
			}
		}
		&__item--level-1{
			.c-navigation__url--level-1{
				color:var(--color-dark-blue);
			}
			&:hover{
				.c-navigation__url--level-1{
					color:white;
				}
			}
		}
		&__parent {
			.c-navigation__url--level-1::after {
				border-left: 5px solid transparent;
				border-right: 5px solid transparent;
				border-top: 6px solid var(--color-primary);
				content: '';
				height: 0;
				margin-left: 6px;
				transition: 0.25s;
				width: 0;
			}

			&:hover {
				.c-navigation__url--level-1 {
					background-color: var(--color-subnav-hover-gray);
					color: var(--color-text-on-primary);
					&::after {
						border-top-color: var(--color-text-on-primary);
					}
				}
			}

			&.is-open {
				.c-navigation__url--level-1 {
					background: var(--color-subnav-hover-gray);
					color: var(--color-text-on-primary);
					&::after {
						border-top-color: var(--color-text-on-primary);
						transform: rotate(-180deg);
						color: var(--color-text-on-primary);
					}
				}
			}
		}
	}

	@media screen and (min-width: 1024px) {
		&__offcanvas {
			top: auto;
		}
		.c-navigation__url--level-1 {
			&:hover {
				background-color: var(--color-subnav-hover-gray);
			}
		}
		&__parent {
			.c-navigation__url--level-1 {
				border-bottom: 3px solid var(--color-secondary);
				&::after {
					content: '';
					width: 0;
					height: 0;
					margin-left: 6px;
					border-left: 5px solid transparent;
					border-right: 5px solid transparent;
					border-top: 6px solid var(--color-text-on-dark);
					transition: 0.25s;
				}
			}

			&.is-open {
				.c-navigation__url--level-1 {
					background-color: var(--color-subnav-hover-gray);
					color: var(--color-text-on-primary);

					&::after {
						border-top-color: var(--color-text-on-primary);
						transform: rotate(-180deg);
					}
				}
			}
		}

		&__level-2 {
			background-color: var(--color-dark);
			opacity: 0;
			position: absolute;
			right: 99999px;
			top: 100%;
			transition: opacity 0.25s, right 0s 0.25s;
			width: 250px;
			&.is-open {
				opacity: 1;
				right: 0;
				transition: opacity 0.25s;
			}
		}
	}
}
</style>
