let default_breakpoint = {
	skip: 0,
	count: 10,
	template: '',
};

export class Breakpoint {
	constructor(breakpoint = default_breakpoint) {
		this.skip = breakpoint.skip;
		this.count = breakpoint.count;
		this.template = breakpoint.template;
	}
}
