<template>
	<div id="app">
		<h1 class="accessible-hide">NCAA.org</h1>
		<main-navigation section-title="Main Navigation" />
		<sub-navigation
			:division="division_id"
			:sport_id="sport_id"
			:type="
				division_id && division_id != 0
					? 'division-navigation'
					: 'sport-navigation'
			"
			v-if="has_sub_nav && (division_id || sport_id)"
		/>
		<router-view />
		<main-footer />
	</div>
</template>

<script>
import MainNavigation from './components/MainNavigation';
import SubNavigation from './components/SubNavigation';
import MainFooter from './components/MainFooter';

export default {
	name: 'App',

	components: {
		MainNavigation,
		SubNavigation,
		MainFooter,
	},
	data() {
		return {scrollPosition: null};
	},
	methods: {
		updateScroll() {
			this.scrollPosition = window.scrollY;
			if(this.scrollPosition >= 40){
				document.getElementsByTagName("HTML")[0].classList.add('not-top');
			}
			else{
				document.getElementsByTagName("HTML")[0].classList.remove('not-top');
			}
		},
	},
	computed: {
		has_sub_nav: function() {
			if (this.$route.meta && this.$route.meta.subnav) {
				return true;
			}
			return false;
		},

		division_id: function() {
			return this.$route.meta.division_id
				? this.$route.meta.division_id
				: null;
		},

		sport_id: function() {
			return this.$route.meta.sport_id ? this.$route.meta.sport_id : null;
		},
	},

	watch: {
		$route(to, from) {
			if (to.name != from.name) {
				if (this.has_sub_nav) {
					document.body.classList.add('has-sub-nav');
				} else {
					document.body.classList.remove('has-sub-nav');
				}
			}
		},
	},
	mounted() {
		window.addEventListener('scroll', this.updateScroll);
	},
};
</script>

<style lang="scss">
:root {
	/* Background Colors */
	--color-primary: #00689e;
	--color-secondary: #e16f10;
	--color-default-theme-page: #fff;
	--color-default-theme-background: #fff;
	--color-default-theme-surface: #f9f9f9;
	--color-default-theme-surface-alt: #e0e0e0;

	/* Text Colors */
	--color-text-on-primary: #fff;
	--color-text-on-secondary: #fff;
	--color-default-theme-default-text: #000;
	--color-default-theme-safe-text: #000;
	--color-default-theme-muted-text: #666;
	--color-default-theme-brand-text: #00689e;
	--color-default-theme-link-text: #00689e;

	/* Stroke Colors */
	--color-default-theme-stroke: #e3e3e3;

	/* Other Colors */
	--color-dark: #000;
	--color-text-on-dark: #fff;
	--color-danger: #f60039;
	--color-text-on-danger: #fff;
	--swiper-navigation-color: #00689e;
	--color-dark-blue: #053962;
	--color-dark-gray: #636a6b;
	--color-nav-lvl2-gray: #dbdbd3;
	--color-nav-border-gray: #b0b3af;
	--color-subnav-hover-gray: #63666a;

	/* Layout Variables */
	--header-height: 70px;
	--full-header-height: var(--header-height);
	--grid-width: 1416px;

	@media screen and (min-width: 1280px) {
		--header-height: 84px;
	}
}

body.has-sub-nav {
	--sub-header-height: 60px;
	--full-header-height: calc(var(--header-height) + var(--sub-header-height));
	@media screen and (min-width: 1024px) {
		--sub-header-height: 70px;
	}
}

a {
	color: var(--color-default-theme-link-text);
}

body {
	font-family: supria-sans, -apple-system, 'Helvetica Neue',
		BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
		'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	margin-top: calc(var(--full-header-height) + 40px);
	min-height: calc(100vh - (var(--full-header-height) + 40px));
}

.button {
	border: 3px solid var(--color-text-on-secondary);
	border-radius: 9999px;
	display: flex;
	font-size: 1rem;
	font-weight: 700;
	align-items: center;
	justify-content: center;
	max-width: 179px;
	min-height: 56px;
	padding: 0 24px;
	text-decoration: none;
	transition: all 0.25s;
	width: 100%;

	&.--on-dark {
		background: transparent;
		border-color: var(--color-text-on-primary);
		color: var(--color-text-on-primary);
		&:hover,
		&:focus {
			background-color: var(--color-text-on-dark);
			color: var(--color-primary);
		}
	}

	&.--on-light {
		background-color: var(--color-white);
		border-color: var(--color-primary);
		color: var(--color-primary);
		&:hover,
		&:focus {
			background-color: var(--color-primary);
			color: var(--color-text-on-primary);
		}
	}

	&:hover,
	&:focus {
		cursor: pointer;
	}

	@media screen and (min-width: 1024px) {
		font-size: 1.125rem;
		min-height: 72px;
		max-width: 224px;
	}
}

.section-header {
	line-height: 3.375rem;
	padding: 0 0 18px;
	position: relative;
	text-align: center;

	&__title {
		font-size: 1.5rem;
		font-weight: 700;
		line-height: 1.125;
		font-family: mislab-std, sans-serif;
	}

	&::after {
		background-color: var(--color-primary);
		content: '';
		position: absolute;
		bottom: 0;
		left: calc(50% - 50px);
		width: 100px;
		height: 6px;
	}

	@media screen and (min-width: 768px) {
		padding: 0 0 30px;

		&__title {
			font-size: 2.25rem;
		}
	}
}

// Utilities
.reset-icon {
	display: inline-flex;

	&::before {
		margin-left: 0;
		margin-right: 0;
	}
}

.max-width-grid {
	max-width: var(--grid-width);
}

// Pagination
ul.pagination {
	user-select: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-khtml-user-select: none;
	-ms-user-select: none;

	.page-item.page-number.active .page-link {
		background: var(--color-primary);
		color: var(--color-text-on-primary);
	}
}

.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.15s;
}
.fade-enter,
.fade-leave-to {
	opacity: 0;
}

.c-panel > div > div > ul {
	padding-inline-start: 20px;
}

.c-panel > div > div > ul > li {
	padding-bottom: 4px;
	list-style-type: initial !important;
}

h2 {
	font-size: 2.25rem !important;
}

h3 {
	font-size: 1.5rem !important;
}

h4 {
	font-size: 1.25rem !important;
}

h5 {
	font-size: 1rem !important;
}

h6 {
	font-size: .875rem !important;
}

.c-panel h3 {
	font-size: 1.25rem !important;
}

.c-panel h4 {
	font-size: 1rem !important;
}

// @media print
// {    
//     .no-print, .no-print * {
//         display: none !important;
//     }

// 	body {
// 		margin-top: 0px !important;
// 	}

// 	.page-template img {
// 		display: block !important;
// 		float: none !important;
// 	}

// 	.text-align-center {
// 		text-align: unset !important;
// 	}

// 	.c-navigation--main {
// 		position: unset !important;
// 	}

// 	.c-footer__copyright {
// 		background: none !important;
//     	color: black;
// 		padding: 10px !important;
// 	}
// }
</style>
