var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.close_dropdowns),expression:"close_dropdowns"}],staticClass:"component c-navigation--main c-navigation position-fixed top-0 left-0 width-100 z-index-1000"},[_c('h2',{staticClass:"accessible-hide"},[_vm._v(_vm._s(_vm.sectionTitle))]),_c('div',{staticClass:"c-navigation__social bg-color-white align-items-center flex-basis-100 display-flex justify-content-center bp-768:justify-content-flex-end bp-768:padding-r-12 bp-1440:padding-r-24"},_vm._l((_vm.icons),function(item,index){return _c('a',{key:index,staticClass:"padding-x-12",attrs:{"href":item.url,"aria-label":item.title}},[_c('span',{class:'sidearm-icon reset-icon ' + item.icon})])}),0),_c('div',{staticClass:"c-navigation__row display-flex justify-content-space-between"},[_c('div',{staticClass:"c-navigation__logo display-flex align-items-center flex-item-auto bg-color-white position-relative z-index-1"},[_c('router-link',{staticClass:"display-block padding-x-12 bp-1280:border-r bp-1280:padding-l-48 bp-1024:padding-r-40 text-decoration-none width-100",attrs:{"to":"/"}},[_c('img',{staticClass:"c-navigation__logo-img display-block",attrs:{"src":require("@/assets/main_logo.svg"),"alt":"NCAA Logo"}})])],1),_c('div',{staticClass:"c-navigation-desktop flex-item-1 height-100 display-none bp-1280:display-block"},[_c('ul',{staticClass:"c-navigation__level-1 reset-list flex-item-1 height-100 utility-flex-center"},_vm._l((_vm.data),function(item){return _c('li',{key:item.id,staticClass:"c-navigation__item--level-1 height-100 transition",class:[
						item.items && item.items.length
							? 'c-navigation__parent'
							: undefined,
						item.is_open ? 'is-open' : undefined ],attrs:{"aria-haspopup":item.items && item.items.length ? 'true' : 'false'}},[(item.items.length && item.id < _vm.data.length)?_c('a',{staticClass:"c-navigation__url c-navigation__url--level-1 font-weight-700 font-size-18 height-100 padding-x-12 bp-1440:padding-x-24 utility-flex-center text-transform-uppercase text-decoration-none text-on-primary hover:bg-color-white transition",class:item.id === 3 || item.id === 4 || item.id === 5
								? 'c-navigation__division'
								: undefined,attrs:{"aria-label":item.title,"aria-haspopup":item.items && item.items.length
								? 'true'
								: 'false',"aria-expanded":item.is_open ? 'true' : 'false',"title":item.title,"href":"javascript:void(0)"},on:{"click":function($event){return _vm.toggle_menu_item(item)},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.toggle_menu_item(item)}}},[_vm._v(_vm._s(item.title))]):_vm._e(),(!item.items.length)?_c('navigation-link',{attrs:{"_class":"c-navigation__url c-navigation__url--level-1 font-weight-700 font-size-18 height-100 padding-x-12 bp-1440:padding-x-24 utility-flex-center text-transform-uppercase text-decoration-none text-on-primary hover:bg-color-white transition","_aria-label":item.title,"_target":item.open_in_new_window ? '_blank' : '_self',"_title":item.title,"url":item.url,"_tab-index":"0"}},[_vm._v(_vm._s(item.title))]):_vm._e(),(item.items.length && item.id < _vm.data.length)?_c('div',{staticClass:"c-navigation__level-2 position-absolute top-100 left-0 width-100",class:item.is_open ? 'transition' : undefined},[_c('div',{staticClass:"c-navigation__level-2-inner display-flex margin-x-auto padding-t-32 padding-b-72 padding-x-12"},_vm._l((item.columns),function(column){return _c('ul',{key:column.column,staticClass:"c-navigation__column reset-list flex-item-1 margin-x-32"},_vm._l((column.items),function(_item){return _c('li',{key:_item.id,staticClass:"c-navigation__item--level-2",class:[
										_item.separator
											? 'c-navigation__separator font-size-16 font-weight-700 padding-12'
											: '',
										_item.class_name ]},[(!_item.separator)?_c('navigation-link',{attrs:{"_class":"c-navigation__url c-navigation__url--level-2 display-flex align-items-center font-size-16 padding-12 text-default text-decoration-none hover:text-on-primary hover:text-decoration-underline transition","_aria-label":_item.title,"url":_item.url ? _item.url : '',"_tab-index":item.is_open ? '0' : undefined,"_target":_item.open_in_new_window
												? '_blank'
												: '_self',"_title":_item.title}},[(
												_item.class_name ===
													'include-logo'
											)?_c('img',{staticClass:"margin-r-6",attrs:{"height":"30px","src":require("@/assets/main_logo.svg"),"alt":"NCAA Logo"}}):_vm._e(),_c('span',[_vm._v(_vm._s(_item.title))])]):_vm._e(),(_item.separator)?_c('span',[_vm._v(_vm._s(_item.title))]):_vm._e()],1)}),0)}),0)]):_vm._e()],1)}),0)]),_c('div',{staticClass:"c-navigation-mobile bp-1280:display-none height-100"},[_c('div',{staticClass:"c-navigation__offcanvas bg-color-white position-absolute top-100 right-0 width-100",class:_vm.mobile_menu_open ? 'is-open' : undefined},[_c('ul',{staticClass:"c-navigation__level-1 reset-list padding-b-72"},_vm._l((_vm.data),function(item){return _c('li',{key:item.id,staticClass:"c-navigation__item--level-1 border-b",class:[
							item.items && item.items.length
								? 'c-navigation__parent'
								: undefined,
							item.is_open ? 'is-open' : undefined ]},[(
								item.items.length && item.id < _vm.data.length + 1
							)?_c('a',{staticClass:"c-navigation__url c-navigation__url--level-1 align-items-center display-flex font-size-18 font-weight-700 padding-24 text-default text-decoration-none text-transform-uppercase transition width-100",attrs:{"tabindex":"0","aria-label":item.title,"aria-haspopup":item.items && item.items.length
									? 'true'
									: 'false',"aria-expanded":item.is_open ? 'true' : 'false',"title":item.title,"href":"javascript:void(0)"},on:{"click":function($event){$event.preventDefault();item.items && item.items.length
									? _vm.toggle_menu_item(item)
									: undefined},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }item.items && item.items.length
									? _vm.toggle_menu_item(item)
									: undefined}}},[_vm._v(" "+_vm._s(item.title)+" ")]):_vm._e(),(
								!item.items.length && item.id < _vm.data.length
							)?_c('navigation-link',{attrs:{"_class":"c-navigation__url c-navigation__url--level-1 align-items-center display-flex font-size-18 font-weight-700 padding-24 text-default text-decoration-none text-transform-uppercase transition hover:bg-color-primary","_aria-label":item.title,"_target":item.open_in_new_window ? '_blank' : '_self',"_title":item.title,"url":item.url ? item.url : '',"tab-index":'0'}},[_vm._v(_vm._s(item.title))]):_vm._e(),(item.items.length)?_c('div',{staticClass:"c-navigation__level-2",class:item.is_open ? 'is-open' : undefined},[_c('ul',{staticClass:"reset-list bg-color-surface"},_vm._l((item.items),function(_item){return _c('li',{key:_item.id,staticClass:"c-navigation__item--level-2",class:[
										_item.separator
											? 'c-navigation__separator font-size-18'
											: undefined,
										_item.class_name ]},[(!_item.separator)?_c('navigation-link',{attrs:{"_class":"c-navigation__url c-navigation__url--level-2 border-b display-block font-size-16 padding-24 text-default text-decoration-none transition hover:bg-color-primary hover:text-on-primary","url":_item.url ? _item.url : '',"tab-index":item.is_open ? '0' : undefined}},[_vm._v(_vm._s(_item.title))]):_vm._e(),(_item.separator)?_c('span',{staticClass:"display-block border-b font-weight-700 padding-24"},[_vm._v(_vm._s(_item.title))]):_vm._e()],1)}),0)]):_vm._e()],1)}),0)])]),_c('search',{staticClass:"margin-l-auto bp-1280:margin-l-auto bp-1280:margin-r-24 bp-1440:margin-r-48"}),_vm._l((_vm.data),function(item){return _c('div',{key:item.id,staticClass:"c-navigation__register--desktop font-weight-700 font-size-18 height-100 display-none bp-1280:display-block"},[_c('div',{staticClass:"c-navigation__register--desktop__item--level-1 height-100 transition",class:[
					item.items && item.items.length
						? 'c-navigation__register--desktop__parent'
						: undefined,
					item.is_open ? 'is-open' : undefined ],attrs:{"aria-haspopup":item.items && item.items.length ? 'true' : 'false'}},[(item.items.length && item.id == _vm.data.length)?_c('a',{staticClass:"c-navigation__register--desktop__url--level-1 display-flex height-100 align-items-center text-transform-uppercase padding-r-48 padding-l-32 bp-1440:padding-r-64 bp-1440:padding-l-48 text-decoration-none transition",class:item.id === 3 || item.id === 4 || item.id === 5
							? 'c-navigation__division'
							: undefined,attrs:{"aria-label":item.title,"aria-haspopup":item.items && item.items.length
							? 'true'
							: 'false',"aria-expanded":item.is_open ? 'true' : 'false',"title":item.title,"href":"javascript:void(0)"},on:{"click":function($event){return _vm.toggle_menu_item(item)},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.toggle_menu_item(item)}}},[_c('span',{staticClass:"display-block"},[_vm._v(_vm._s(item.title))])]):_vm._e(),(item.items.length && item.id == _vm.data.length)?_c('div',{staticClass:"c-navigation__register--desktop__level-2 position-absolute top-100 right-0",class:item.is_open ? 'transition' : undefined},[_c('div',{staticClass:"c-navigation__register--desktop__level-2-inner display-flex margin-x-auto padding-t-12 padding-b-24 padding-x-24"},_vm._l((item.columns),function(column){return _c('ul',{key:column.column,staticClass:"c-navigation__register--desktop__column reset-list flex-item-1"},_vm._l((column.items),function(_item){return _c('li',{key:_item.id,staticClass:"c-navigation__register--desktop__item--level-2",class:[
									_item.separator
										? 'c-navigation__register--desktop__separator font-size-16 font-weight-700 padding-12'
										: '',
									_item.class_name ]},[(!_item.separator)?_c('navigation-link',{attrs:{"_class":"c-navigation__register--desktop__url c-navigation__register--desktop__url--level-2 display-flex align-items-center font-size-16 padding-12 text-default text-decoration-none hover:text-on-primary hover:text-decoration-underline transition","_aria-label":_item.title,"url":_item.url ? _item.url : '',"_tab-index":item.is_open ? '0' : undefined,"_target":_item.open_in_new_window
											? '_blank'
											: '_self',"_title":_item.title}},[(
											_item.class_name ===
												'include-logo'
										)?_c('img',{staticClass:"margin-r-6",attrs:{"height":"30px","src":require("@/assets/main_logo.svg"),"alt":"NCAA Logo"}}):_vm._e(),_c('span',{staticClass:"margin-x-auto"},[_vm._v(_vm._s(_item.title))])]):_vm._e(),(_item.separator)?_c('span',[_vm._v(_vm._s(_item.title))]):_vm._e()],1)}),0)}),0)]):_vm._e()])])}),_c('button',{staticClass:"c-navigation__toggle bp-1280:display-none reset height-100 border-l",class:_vm.mobile_menu_open ? 'is-toggled' : undefined,on:{"click":function($event){$event.preventDefault();return _vm.toggle_mobile_menu($event)}}},[_c('svg',{staticClass:"hamburger height-100 width-100",attrs:{"viewBox":"0 0 100 100"}},[_c('path',{staticClass:"line top",attrs:{"d":"m 30,33 h 40 c 3.722839,0 7.5,3.126468 7.5,8.578427 0,5.451959 -2.727029,8.421573 -7.5,8.421573 h -20"}}),_c('path',{staticClass:"line middle",attrs:{"d":"m 30,50 h 40"}}),_c('path',{staticClass:"line bottom",attrs:{"d":"m 70,67 h -40 c 0,0 -7.5,-0.802118 -7.5,-8.365747 0,-7.563629 7.5,-8.634253 7.5,-8.634253 h 20"}})])])],2)])}
var staticRenderFns = []

export { render, staticRenderFns }